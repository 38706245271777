import logomark from "assets/logomark.png"
import { ReactComponent as Wordmark } from "assets/Wordmark.svg"
import AutoLink from "components/AutoLink"
import ContactPanel from "components/ContactPanel"
import InvisibleButton from "components/InvisibleButton"
import { useAppState } from "components/Layout"
import { AnimatePresence, motion } from "framer-motion"
import { graphql, useStaticQuery } from "gatsby"
import useArrowFocus from "hooks/useArrowFocus"
import useMediaQuery from "hooks/useMediaQuery"
import useOnClickOutside from "hooks/useOnClickOutside"
import throttle from "lodash-es/throttle"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import theme from "styles/theme"

// const mobileBreak = theme.mq.max(960)
const mobileBreak = theme.mq.max(1280)

// Globe icon for language dropdown
const GlobeIcon = (props) => (
  <svg viewBox="0 0 24 24" width="22" height="22" {...props}>
    <path
      fill="currentColor"
      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-2.29-2.333A17.9 17.9 0 0 1 8.027 13H4.062a8.008 8.008 0 0 0 5.648 6.667zM10.03 13c.151 2.439.848 4.73 1.97 6.752A15.905 15.905 0 0 0 13.97 13h-3.94zm9.908 0h-3.965a17.9 17.9 0 0 1-1.683 6.667A8.008 8.008 0 0 0 19.938 13zM4.062 11h3.965A17.9 17.9 0 0 1 9.71 4.333 8.008 8.008 0 0 0 4.062 11zm5.969 0h3.938A15.905 15.905 0 0 0 12 4.248 15.905 15.905 0 0 0 10.03 11zm4.259-6.667A17.9 17.9 0 0 1 15.973 11h3.965a8.008 8.008 0 0 0-5.648-6.667z"
    />
  </svg>
)

// Define available locales for language dropdown
const locales = [
  {
    lang: "English",
    locale: "en-GB",
    href: "https://www.secondmind.ai/",
  },
  {
    lang: "日本語",
    locale: "ja-JP",
    href: "https://www.secondmind.jp/",
  },
]

const Header = ({ currentPath }) => {
  const { data } = useStaticQuery(headerQuery)
  const mobile = useMediaQuery(mobileBreak) ?? true
  const [home, setHome] = useState(true)
  const [open, setOpen] = useState(false)
  const headerRef = useRef({})
  const { contactPanelOpen, dispatch } = useAppState()
  useArrowFocus(headerRef, ["ArrowLeft", "ArrowRight"])

  useEffect(() => {
    if (mobile) return

    const handleScroll = throttle(() => {
      const scrollPos = window.pageYOffset || document.documentElement.scrollTop
      setHome(scrollPos < 5)
    })

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [mobile, setHome])

  return (
    <header
      ref={headerRef}
      css={{
        position: "fixed",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        background: "inherit",
        color: theme.colors.secondaryText,
        padding: "10px 25px",
        margin: "50px 0",
        left: `calc(50vw - ((100vw - ${home ? 200 : 100}px) / 2))`,
        width: "100%",
        transition:
          "max-width 300ms, left 300ms, box-shadow 300ms, transform 300ms",
        transform: `translateY(${home ? 0 : -25}px)`,
        maxWidth: `calc(100vw - ${home ? 200 : 100}px)`,
        boxShadow: home && theme.shadows.tight,
        borderRadius: 4,
        zIndex: 6,
        ":after": {
          content: "''",
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          boxShadow: theme.shadows.blur,
          borderRadius: 4,
          opacity: home ? 0 : 1,
          transition: "opacity 300ms",
          pointerEvents: "none",
          zIndex: 6,
        },
        [theme.mq.max(1024)]: {
          left: "calc(50vw - ((100vw - 100px) / 2))",
          maxWidth: "calc(100vw - 100px)",
        },
        [theme.mq.mobile]: {
          margin: "25px 0",
          left: 25,
          right: 25,
          width: "auto",
          maxWidth: "none",
          padding: "8px 13px",
          transform: "translateY(0)",
        },
        [`nav a[href="${currentPath}"]`]: {
          color: theme.colors.accentColor,
          opacity: 1,
        },
      }}
    >
      <LogoLink />

      {mobile ? (
        <MobileNavToggle
          open={open}
          onClick={() => {
            dispatch("closeContactPanel")
            setOpen((prev) => !prev)
          }}
        />
      ) : (
        <DesktopNavLinks
          home={home}
          links={data.links}
          currentPath={currentPath}
        />
      )}

      <AnimatePresence initial={false}>
        {open && mobile ? (
          <React.Fragment>
            <MobileNavLinks
              links={data.links}
              onRequestClose={() => {
                setOpen(false)
              }}
              css={{
                position: "absolute",
                zIndex: 7,
                right: 0,
                top: "100%",
                minWidth: 270,
                maxHeight: "calc(100vh - 60px - 75px)", // viewport - header height - top & bottom margin
                overflowY: "auto",
                [theme.mq.mobile]: {
                  maxHeight: "calc(100vh - 42px - 50px)",
                },
                [theme.mq.max(450)]: {
                  width: "100%",
                },
              }}
            />
          </React.Fragment>
        ) : (
          contactPanelOpen && <ContactPanel />
        )}
      </AnimatePresence>
    </header>
  )
}

export default Header

Header.propTypes = {
  currentPath: PropTypes.string,
}

const LogoLink = () => (
  <AutoLink
    to="/"
    css={{
      color: theme.colors.primaryText,
      display: "flex",
      alignItems: "center",
    }}
  >
    <video
      poster={logomark}
      autoPlay
      loop
      muted
      playsInline
      alt=""
      css={{
        display: "block",
        width: 40,
        height: 40,
        marginRight: 12,
        [theme.mq.mobile]: {
          width: 25,
          height: 25,
          marginRight: 8,
        },
      }}
    >
      <source src="/logo/logomark-small.mov" type="video/quicktime" />
      <source src="/logo/logomark-small.webm" type="video/webm" />
    </video>
    <Wordmark
      css={{
        width: 143,
        [theme.mq.mobile]: {
          width: 95,
        },
      }}
    />
  </AutoLink>
)

const MobileNavToggle = ({ open, ...props }) => (
  <InvisibleButton aria-label="Toggle Nav Menu" {...props}>
    <svg width={20} fill={theme.colors.accentColor} viewBox="0 0 18 16">
      {open ? (
        <path stroke="#AB75FA" strokeWidth="2" d="M16 1L2 15M2 1l14 14" />
      ) : (
        <>
          <path fill="#AB75FA" d="M0 1h18v2H0V1z" />
          <path fill="#AB75FA" d="M0 7h18v2H0V7z" />
          <path fill="#AB75FA" d="M0 13h18v2H0v-2z" />
        </>
      )}
    </svg>
  </InvisibleButton>
)

MobileNavToggle.propTypes = {
  open: PropTypes.bool.isRequired,
}

const linkStyles = {
  ...theme.p3,
  color: theme.colors.secondaryText,
  textDecoration: "none",
  transition: "color 200ms",
  userSelect: "none",
  ":hover": { color: theme.colors.accentColor },
}

const navItemStyles = {
  marginLeft: 25,
  display: "flex",
  alignItems: "center",
}

const LanguageDropdown = () => {
  const [open, setOpen] = useState(false)
  const focusRef = useRef()
  const dropdownRef = useRef()

  useOnClickOutside(
    dropdownRef,
    () => {
      setOpen(false)
    },
    open
  )

  useArrowFocus(focusRef)

  return (
    <div
      ref={focusRef}
      css={{ ...navItemStyles, alignItems: "stretch", position: "relative" }}
    >
      <InvisibleButton
        css={{
          ...linkStyles,
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          setOpen((prev) => !prev)
        }}
        tabIndex="0"
      >
        <GlobeIcon css={{ marginRight: "0.5em" }} />
        {locales.find(({ locale }) => locale === process.env.LOCALE).lang}
      </InvisibleButton>
      <div
        ref={dropdownRef}
        css={{
          position: "absolute",
          top: "calc(100% + 10px)",
          background: theme.colors.primaryBackground,
          boxShadow: theme.shadows.tight,
          padding: "20px 28px 8px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          whiteSpace: "nowrap",
          borderRadius: "0px 0px 4px 4px",
          left: -46,
          marginLeft: "30%",
          pointerEvents: open ? "initial" : "none",
          opacity: open ? 1 : 0,
          transformOrigin: "center top",
          transform: open
            ? "perspective(500px) rotateX(0deg)"
            : "perspective(500px) rotateX(-90deg)",
          transition: "transform 300ms, opacity 250ms",
        }}
      >
        {locales
          .filter(({ locale }) => locale !== process.env.LOCALE)
          .map(({ lang, locale, href }) => (
            <React.Fragment key={locale}>
              <AutoLink
                to={href}
                css={{ ...linkStyles, marginBottom: 12 }}
                tabIndex={open ? 0 : -1}
              >
                {lang}
              </AutoLink>
              <hr
                css={{
                  width: "100%",
                  height: 1,
                  border: 0,
                  marginTop: 0,
                  marginBottom: 12,
                  background: "currentColor",
                  opacity: 0.3,
                  ":last-child": { display: "none" },
                }}
              />
            </React.Fragment>
          ))}
      </div>
    </div>
  )
}

const Dropdown = ({ label, links, currentPath, className }) => {
  const [open, setOpen] = useState(false)
  const focusRef = useRef()
  const dropdownRef = useRef()

  useOnClickOutside(
    dropdownRef,
    () => {
      setOpen(false)
    },
    open
  )

  useArrowFocus(focusRef)

  return (
    <div
      ref={focusRef}
      css={{ ...navItemStyles, alignItems: "stretch", position: "relative" }}
    >
      <InvisibleButton
        css={{
          ...linkStyles,
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          color:
            links.some(({ url }) => url === currentPath) &&
            theme.colors.accentColor,
        }}
        onClick={() => {
          setOpen((prev) => !prev)
        }}
        tabIndex="0"
      >
        {label}
      </InvisibleButton>
      <div
        ref={dropdownRef}
        css={{
          position: "absolute",
          top: "calc(100% + 10px)",
          background: theme.colors.primaryBackground,
          boxShadow: theme.shadows.tight,
          padding: "20px 28px 8px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          whiteSpace: "nowrap",
          borderRadius: "0px 0px 4px 4px",
          left: -46,
          marginLeft: "30%",
          pointerEvents: open ? "initial" : "none",
          opacity: open ? 1 : 0,
          transformOrigin: "center top",
          transform: open
            ? "perspective(500px) rotateX(0deg)"
            : "perspective(500px) rotateX(-90deg)",
          transition: "transform 300ms, opacity 250ms",
        }}
        className={className}
      >
        {links.map(({ _key, title, url }) => (
          <React.Fragment key={_key}>
            <AutoLink
              to={url}
              css={{ ...linkStyles, marginBottom: 12 }}
              tabIndex={open ? 0 : -1}
            >
              {title}
            </AutoLink>
            <hr
              css={{
                width: "100%",
                height: 1,
                border: 0,
                marginTop: 0,
                marginBottom: 12,
                background: "currentColor",
                opacity: 0.3,
                ":last-child": { display: "none" },
              }}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

Dropdown.propTypes = {
  label: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      _key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  currentPath: PropTypes.string,
  className: PropTypes.string,
}

const DesktopNavLinks = ({ links, home, currentPath, className }) => (
  <nav
    css={{ display: "flex", alignSelf: "stretch", alignItems: "stretch" }}
    className={className}
  >
    {links.map(({ _type, _key, ...entry }) =>
      _type === "link" ? (
        <AutoLink key={_key} to={entry.url} css={[linkStyles, navItemStyles]}>
          {entry.title}
        </AutoLink>
      ) : (
        <Dropdown
          key={_key}
          label={entry.text}
          links={entry.dropdownLinks}
          css={{
            zIndex: 7,
            boxShadow: !home && theme.shadows.blur,
          }}
          currentPath={currentPath}
        />
      )
    )}
    <LanguageDropdown />
  </nav>
)

DesktopNavLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        _type: PropTypes.string.isRequired,
        _key: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
      PropTypes.shape({
        _type: PropTypes.string.isRequired,
        _key: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        dropdownLinks: PropTypes.arrayOf(
          PropTypes.shape({
            _key: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
          }).isRequired
        ).isRequired,
      }),
    ]).isRequired
  ).isRequired,
  home: PropTypes.bool,
  currentPath: PropTypes.string,
  className: PropTypes.string,
}

const MobileNavLinks = ({ links, onRequestClose, className }) => {
  const ref = useRef()

  useOnClickOutside(ref, onRequestClose)

  return (
    <motion.nav
      ref={ref}
      css={{
        display: "flex",
        flexDirection: "column",
        background: "inherit",
        boxShadow: theme.shadows.tight,
        borderRadius: 4,
        padding: "8px 20px",
      }}
      className={className}
      initial={{ opacity: 0, rotateX: -90, originY: 0, perspective: 500 }}
      animate={{ opacity: 1, rotateX: 0 }}
      exit={{ opacity: 0, rotateX: -90 }}
      transition={{ type: "spring", damping: 24, stiffness: 200 }}
    >
      {links.map(({ _type, _key, title, url, text, dropdownLinks }) =>
        _type === "link" ? (
          <div
            key={_key}
            css={{
              borderBottom: `1px solid ${theme.colors.divider}`,
              padding: "8px 0",
              ":last-child": {
                borderBottom: 0,
              },
            }}
          >
            <AutoLink
              to={url}
              css={mobileNavItemStyles}
              onClick={onRequestClose}
            >
              {title}
            </AutoLink>
          </div>
        ) : (
          <div
            key={_key}
            css={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              borderBottom: `1px solid ${theme.colors.divider}`,
              padding: "8px 0",
              ":last-child": {
                borderBottom: 0,
              },
            }}
          >
            <div
              css={{
                ...mobileNavItemStyles,
                pointerEvents: "none",
                marginBottom: 8,
              }}
            >
              {text}
            </div>
            {dropdownLinks.map(({ _key, title, url }) => (
              <AutoLink
                key={_key}
                to={url}
                onClick={onRequestClose}
                css={[
                  mobileNavItemStyles,
                  {
                    display: "block",
                    marginLeft: 10,
                    fontWeight: 400,
                    opacity: 0.6,
                    transition:
                      mobileNavItemStyles.transition + ", opacity 300ms",
                    ":hover": {
                      opacity: 1,
                    },
                  },
                ]}
              >
                {title}
              </AutoLink>
            ))}
          </div>
        )
      )}
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          borderBottom: `1px solid ${theme.colors.divider}`,
          padding: "8px 0",
          ":last-child": {
            borderBottom: 0,
          },
        }}
      >
        <div
          css={{
            ...mobileNavItemStyles,
            pointerEvents: "none",
            marginBottom: 8,
          }}
        >
          Language
        </div>
        {locales
          .filter(({ locale }) => locale !== process.env.LOCALE)
          .map(({ lang, locale, href }) => (
            <AutoLink
              key={locale}
              to={href}
              onClick={onRequestClose}
              css={[
                mobileNavItemStyles,
                {
                  display: "block",
                  marginLeft: 10,
                  fontWeight: 400,
                  opacity: 0.6,
                  transition:
                    mobileNavItemStyles.transition + ", opacity 300ms",
                  ":hover": {
                    opacity: 1,
                  },
                },
              ]}
            >
              {lang}
            </AutoLink>
          ))}
      </div>
    </motion.nav>
  )
}

MobileNavLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        _type: PropTypes.string.isRequired,
        _key: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
      PropTypes.shape({
        _type: PropTypes.string.isRequired,
        _key: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        dropdownLinks: PropTypes.arrayOf(
          PropTypes.shape({
            _key: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
          }).isRequired
        ).isRequired,
      }),
    ]).isRequired
  ).isRequired,
  onRequestClose: PropTypes.func.isRequired,
  className: PropTypes.string,
}

const mobileNavItemStyles = {
  ...linkStyles,
  fontWeight: 500,
  color: theme.colors.primaryText,
}

const headerQuery = graphql`
  {
    data: sanityHeaderNavigationSettings {
      links {
        ... on SanityDropdownLink {
          _key
          _type
          text
          dropdownLinks {
            _key
            ...Link
          }
        }
        ... on SanityLink {
          _key
          _type
          ...Link
        }
      }
    }
  }
`
